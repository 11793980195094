import dayjs from 'dayjs'
import {
  BusinessHours,
  Carrier,
  CarrierContacts,
  CarrierDetails,
  LoadDetailsInterface,
  LoadStop,
  RawCarrierBuildingContact,
  RawCarrierBusinessPartnerContact,
  RawCarrierContacts,
  RawShipmentDetails,
  Scac,
  ShipmentActionInterface,
  ShipmentRowInterface,
} from 'common/types'
import { SERVICE_SHIPMENT_ENUM, SHIPMENT_STATUS } from 'common/constants'
import { pickupDateChangeReasons } from 'constants/pickupDateChangeReason'

export const checkIsShipmentEditable = (
  shipmentStatus: SHIPMENT_STATUS,
  toBeCancelled: boolean,
  isCancelled: boolean,
  isOnLoad: boolean,
  isProjectedCancel: boolean,
  hasRequiredEntitlements: boolean,
) => {
  if (!hasRequiredEntitlements) {
    return false
  }

  if (isProjectedCancel) {
    return false
  }

  if (toBeCancelled || isCancelled) {
    return false
  }

  return !(
    shipmentStatus === SHIPMENT_STATUS.PICKED_UP ||
    shipmentStatus === SHIPMENT_STATUS.PROCESSING_MODIFICATION
  )
}

export const getPickupDateToUse = (shipment?: any, loadDetails?: any) => {
  const loadPickupDate = loadDetails?.loadPickup?.split('T')

  const isPickupDateOverridden =
    shipment?.user_provided_pickup_date &&
    loadDetails?.loadPickup &&
    shipment.user_provided_pickup_date !== loadPickupDate?.[0]

  return {
    pickupDate: isPickupDateOverridden
      ? loadPickupDate?.[0]
      : shipment.user_provided_pickup_date || loadPickupDate?.[0],
    pickupReasonCode: isPickupDateOverridden
      ? pickupDateChangeReasons.TGT_DRIVEN_PICKUP_DATE_OVERRIDE.label
      : shipment.pickup_date_change_reason,
  }
}

export const getCreatedBy = (shipment: any) => {
  if (
    shipment?.created_by !== 'SYSTEM' &&
    shipment?.created_by_email !== undefined
  ) {
    return shipment?.created_by_email
  }

  return shipment?.created_by
}

export const getLatestAction = (actions: Array<ShipmentActionInterface>) => {
  const latestAction = actions.reduce((a, b) => {
    return a?.date > b?.date ? a : b
  })
  return latestAction.date !== ''
    ? latestAction.type
    : SERVICE_SHIPMENT_ENUM.NO_ACTION_YET
}

export const getShipmentStatus = (
  shipment: RawShipmentDetails,
  loadDetails: LoadDetailsInterface,
  hasShipmentProjections: boolean,
  isProjectedCancel: boolean,
  isInternal?: boolean,
) => {
  if (!shipment || !loadDetails) {
    return SHIPMENT_STATUS.NO_STATUS
  }

  const { status = '', vendor_release = '', small_package } = shipment

  const {
    loadId = '',
    loadPickup = '',
    moveComplete = '',
    moveStart = '',
    planned = '',
    tender = '',
    tenderAccept = '',
    tenderCancel = '',
    tenderReject = '',
  } = loadDetails

  // Get latest time based action. Shipments can process forwards or backwards therefore we need get the latest action by date
  const latestAction = getLatestAction([
    { type: SERVICE_SHIPMENT_ENUM.MOVE_COMPLETE, date: moveComplete },
    { type: SERVICE_SHIPMENT_ENUM.MOVE_START, date: moveStart },
    { type: SERVICE_SHIPMENT_ENUM.PLANNED, date: planned },
    { type: SERVICE_SHIPMENT_ENUM.TENDER, date: tender },
    { type: SERVICE_SHIPMENT_ENUM.TENDER_ACCEPT, date: tenderAccept },
    { type: SERVICE_SHIPMENT_ENUM.TENDER_CANCEL, date: tenderCancel },
    { type: SERVICE_SHIPMENT_ENUM.TENDER_REJECT, date: tenderReject },
  ])

  // Shipments that have been touched through modification
  const hasShipmentBeenTouchedByUser =
    vendor_release === SERVICE_SHIPMENT_ENUM.VENDOR_ACKNOWLEDGED ||
    vendor_release === SERVICE_SHIPMENT_ENUM.REREVIEW_REQUIRED

  if (status === SERVICE_SHIPMENT_ENUM.FAILED) {
    return SHIPMENT_STATUS.FAILED_MODIFICATION
  }

  if (isProjectedCancel) {
    return SHIPMENT_STATUS.PROCESSING_CANCEL
  }

  if (hasShipmentProjections) {
    return SHIPMENT_STATUS.PROCESSING_MODIFICATION
  }

  if (
    vendor_release === SERVICE_SHIPMENT_ENUM.PENDING_REVIEW &&
    status !== SERVICE_SHIPMENT_ENUM.CANCELLED &&
    status !== SERVICE_SHIPMENT_ENUM.PURCHASE_ORDER_CANCELLED
  ) {
    return SHIPMENT_STATUS.CONTENT_REVIEW_REQUIRED
  }

  if (small_package && status === SERVICE_SHIPMENT_ENUM.SHIP_SMALL_PARCEL) {
    return SHIPMENT_STATUS.SMALL_PACKAGE
  }

  if (status === SERVICE_SHIPMENT_ENUM.SENT_FOR_ROUTING_FAILED) {
    return isInternal
      ? SHIPMENT_STATUS.SENT_FOR_ROUTING_FAILED
      : SHIPMENT_STATUS.ON_HOLD_FOR_ROUTING
  }

  if (status === SERVICE_SHIPMENT_ENUM.CANCEL_SYNC_FAILED) {
    return SHIPMENT_STATUS.CANCELLED
  }

  if (
    latestAction === SERVICE_SHIPMENT_ENUM.TENDER_REJECT ||
    latestAction === SERVICE_SHIPMENT_ENUM.PLANNED ||
    latestAction === SERVICE_SHIPMENT_ENUM.TENDER_CANCEL
  ) {
    return SHIPMENT_STATUS.AWAITING_CARRIER_ASSIGNMENT
  }

  if (latestAction === SERVICE_SHIPMENT_ENUM.TENDER) {
    return SHIPMENT_STATUS.AWAITING_CARRIER_ACCEPTANCE
  }

  if (
    loadPickup &&
    (latestAction === SERVICE_SHIPMENT_ENUM.MOVE_COMPLETE ||
      latestAction === SERVICE_SHIPMENT_ENUM.MOVE_START)
  ) {
    return SHIPMENT_STATUS.PICKED_UP
  }
  // This condition is needed when Target Fleet picks up the product they do not send EDIs to update the load status
  if (
    loadDetails.pickedUp &&
    ((loadDetails.carrier.assignedScac.id === 'TRGE' &&
      !loadDetails.carrier.executingScac.id) ||
      loadDetails.carrier.executingScac.id === 'TRGE')
  ) {
    return SHIPMENT_STATUS.PICKED_UP
  }

  if (loadPickup && latestAction !== SERVICE_SHIPMENT_ENUM.MOVE_START) {
    const today = new Date()
    const pickupDate = new Date(loadPickup)
    if (86400000 < today.getTime() - pickupDate.getTime()) {
      return SHIPMENT_STATUS.PAST_PICKUP
    }
  }

  if (loadId && latestAction === SERVICE_SHIPMENT_ENUM.TENDER_ACCEPT) {
    return SHIPMENT_STATUS.CARRIER_ACCEPTED_AWAITING_PICKUP
  }

  if (
    status === SERVICE_SHIPMENT_ENUM.SENT_FOR_ROUTING &&
    loadId &&
    hasShipmentBeenTouchedByUser
  ) {
    return SHIPMENT_STATUS.AWAITING_CARRIER_ACCEPTANCE
  }

  if (
    status === SERVICE_SHIPMENT_ENUM.SENT_FOR_ROUTING &&
    !loadId &&
    hasShipmentBeenTouchedByUser
  ) {
    return SHIPMENT_STATUS.RETRIEVING_PICKUP_DATE
  }

  // Shipments created manually by vendor get a new status
  if (status === SERVICE_SHIPMENT_ENUM.NEW && hasShipmentBeenTouchedByUser) {
    return SHIPMENT_STATUS.ON_HOLD_FOR_ROUTING
  }

  if (status === SERVICE_SHIPMENT_ENUM.CANCELLED) {
    return SHIPMENT_STATUS.CANCELLED
  }

  if (status === SERVICE_SHIPMENT_ENUM.PURCHASE_ORDER_CANCELLED) {
    return SHIPMENT_STATUS.PURCHASE_ORDER_CANCELLED
  }

  return SHIPMENT_STATUS.STATUS_NOT_FOUND
}

interface TransformShipmentsOptions {
  rawShipments: RawShipmentDetails[] | undefined
  hasRequiredEntitlements?: boolean
  vmmLocationId?: string
  businessHrsBasedPickup?: boolean
  businessHours?: Array<BusinessHours>
  closureDates?: any[]
  isInternal?: boolean
}

export const transformShipments = ({
  rawShipments,
  hasRequiredEntitlements = true,
  vmmLocationId,
  businessHrsBasedPickup,
  businessHours,
  closureDates,
  isInternal,
}: TransformShipmentsOptions): Record<string, ShipmentRowInterface> => {
  if (!Array.isArray(rawShipments)) {
    return {}
  }
  return rawShipments.reduce((prev: any, curr: any) => {
    const { load_details, status, toBeCancelled } = curr

    const executingCarrierContactDetails: RawCarrierContacts | undefined =
      load_details?.carrier?.executing_scac?.carrier_details?.carrier_contacts

    const mappedExecutingCarrierContacts: CarrierContacts | undefined =
      executingCarrierContactDetails
        ? {
            address: {
              city: executingCarrierContactDetails.address?.city,
              country: executingCarrierContactDetails.address?.country,
              state: executingCarrierContactDetails.address?.state,
              street: executingCarrierContactDetails.address?.street,
              zipCode: executingCarrierContactDetails.address?.zip_code,
            },
            buildingContacts: executingCarrierContactDetails.building_contacts
              ? executingCarrierContactDetails.building_contacts.map(
                  (rawCarrierBuildingContact: RawCarrierBuildingContact) => ({
                    buildingId: rawCarrierBuildingContact?.building_id,
                    emails: rawCarrierBuildingContact?.emails,
                    phoneNumber: rawCarrierBuildingContact?.phone_number,
                  }),
                )
              : undefined,
            businessPartnerContacts:
              executingCarrierContactDetails.business_partner_contacts
                ? executingCarrierContactDetails.business_partner_contacts.map(
                    (
                      rawCarrierBusinessPartnerContact: RawCarrierBusinessPartnerContact,
                    ) => ({
                      email: rawCarrierBusinessPartnerContact?.email,
                      firstName: rawCarrierBusinessPartnerContact?.first_name,
                      lastName: rawCarrierBusinessPartnerContact?.last_name,
                      name: rawCarrierBusinessPartnerContact?.name,
                      userId: rawCarrierBusinessPartnerContact?.user_id,
                    }),
                  )
                : undefined,
            emails: executingCarrierContactDetails.emails,
            primaryPhone: executingCarrierContactDetails.primary_phone,
            secondaryPhone: executingCarrierContactDetails.secondary_phone,
          }
        : undefined

    const executingCarrierDetails: CarrierDetails | undefined = {
      active: load_details?.carrier?.executing_scac?.carrier_details?.active,
      carrierContacts: mappedExecutingCarrierContacts,
      contactName:
        load_details?.carrier?.executing_scac?.carrier_details?.contact_name,
      description:
        load_details?.carrier?.executing_scac?.carrier_details?.description,
      name: load_details?.carrier?.executing_scac?.carrier_details?.name,
      scac: load_details?.carrier?.executing_scac?.carrier_details?.scac,
    }

    const executingScac: Scac = {
      id: load_details?.carrier?.executing_scac?.id,
      name: load_details?.carrier?.executing_scac?.name,
      carrierDetails: executingCarrierDetails,
    }

    const assignedCarrierContactDetails: RawCarrierContacts | undefined =
      load_details?.carrier?.assigned_scac?.carrier_details?.carrier_contacts

    const mappedAssignedCarrierContacts: CarrierContacts | undefined =
      assignedCarrierContactDetails
        ? {
            address: {
              city: assignedCarrierContactDetails.address?.city,
              country: assignedCarrierContactDetails.address?.country,
              state: assignedCarrierContactDetails.address?.state,
              street: assignedCarrierContactDetails.address?.street,
              zipCode: assignedCarrierContactDetails.address?.zip_code,
            },
            buildingContacts: assignedCarrierContactDetails.building_contacts
              ? assignedCarrierContactDetails.building_contacts?.map(
                  (rawCarrierBuildingContact: RawCarrierBuildingContact) => ({
                    buildingId: rawCarrierBuildingContact.building_id,
                    emails: rawCarrierBuildingContact.emails,
                    phoneNumber: rawCarrierBuildingContact.phone_number,
                  }),
                )
              : undefined,
            businessPartnerContacts:
              assignedCarrierContactDetails.business_partner_contacts
                ? assignedCarrierContactDetails.business_partner_contacts.map(
                    (
                      rawCarrierBusinessPartnerContact: RawCarrierBusinessPartnerContact,
                    ) => ({
                      email: rawCarrierBusinessPartnerContact?.email,
                      firstName: rawCarrierBusinessPartnerContact?.first_name,
                      lastName: rawCarrierBusinessPartnerContact?.last_name,
                      name: rawCarrierBusinessPartnerContact?.name,
                      userId: rawCarrierBusinessPartnerContact?.user_id,
                    }),
                  )
                : undefined,
            emails: assignedCarrierContactDetails.emails,
            primaryPhone: assignedCarrierContactDetails.primary_phone,
            secondaryPhone: assignedCarrierContactDetails.secondary_phone,
          }
        : undefined

    const assignedCarrierDetails: CarrierDetails = {
      active: load_details?.carrier?.assigned_scac?.carrier_details?.active,
      carrierContacts: mappedAssignedCarrierContacts,
      contactName:
        load_details?.carrier?.assigned_scac?.carrier_details?.contact_name,
      description:
        load_details?.carrier?.assigned_scac?.carrier_details?.description,
      name: load_details?.carrier?.assigned_scac?.carrier_details?.name,
      scac: load_details?.carrier?.assigned_scac?.carrier_details?.scac,
    }

    const assignedScac: Scac = {
      id: load_details?.carrier?.assigned_scac?.id,
      name: load_details?.carrier?.assigned_scac?.name,
      carrierDetails: assignedCarrierDetails,
    }
    const carrier: Carrier = {
      assignedScac,
      executingScac,
    }
    const origin: LoadStop = {
      addressLine: load_details?.origin?.address_line,
      city: load_details?.origin?.city,
      country: load_details?.origin?.country,
      locationCategory: load_details?.origin?.location_category,
      locationCode: load_details?.origin?.location_code,
      locationName: load_details?.origin?.location_name,
      postalCode: load_details?.origin?.postal_code,
      state: load_details?.origin?.state,
      stopSeqNumber: load_details?.origin?.stop_seq_number,
    }

    const destination: LoadStop = {
      addressLine: load_details?.destination?.address_line,
      city: load_details?.destination?.city,
      country: load_details?.destination?.country,
      locationCategory: load_details?.destination?.location_category,
      locationCode: load_details?.destination?.location_code,
      locationName: load_details?.destination?.location_name,
      postalCode: load_details?.destination?.postal_code,
      state: load_details?.destination?.state,
      stopSeqNumber: load_details?.destination?.stop_seq_number,
    }

    const loadDetails: LoadDetailsInterface = {
      alert: load_details?.alert,
      pickupTimeIsInFuture: load_details?.pickup_time_is_in_future,
      subCategory: load_details?.sub_category,
      carrier: carrier,
      destination: destination,
      distanceUom: load_details?.distance_uom,
      drivingDays: load_details?.driving_days,
      droppedOff: load_details?.dropped_off,
      origin: origin,
      pickedUp: load_details?.picked_up,
      totalDistance: load_details?.total_distance,
      totalPickups: load_details?.total_pickups,
      loadId: load_details?.load_id,
      loadPickup:
        load_details?.load_pickup &&
        dayjs(load_details?.load_pickup).format('YYYY-MM-DD'),
      moveComplete: load_details?.load_milestones?.move_complete,
      moveStart: load_details?.load_milestones?.move_start,
      planned: load_details?.load_milestones?.planned,
      proNumber: load_details?.pro_number,
      tender: load_details?.load_milestones?.tender,
      tenderAccept: load_details?.load_milestones?.tender_accept,
      tenderCancel: load_details?.load_milestones?.tender_cancel,
      tenderReject: load_details?.load_milestones?.tender_reject,
      tripId: load_details?.trip_id,
      loadDelivery:
        load_details?.load_delivery &&
        dayjs(load_details?.load_delivery).format('YYYY-MM-DD'),
    }

    const isCancelled =
      status === 'CANCELLED' ||
      status === 'CANCEL_SYNC_FAILED' ||
      status === 'PURCHASE_ORDER_CANCELLED'

    const { booking_modifications } = curr
    const isProjectedCancel =
      booking_modifications?.action === SERVICE_SHIPMENT_ENUM.CANCEL
    const projectedValues = {
      projected_appointmentNumber: booking_modifications?.appointment_number,
      projected_bol: booking_modifications?.bill_of_lading,
      projected_isCancelled: isProjectedCancel,
      projected_carton: booking_modifications?.measures?.carton_quantity,
      projected_pallet: booking_modifications?.measures?.pallet,
      projected_pickupDate: booking_modifications?.pickup_override?.date,
      projected_totalVol: booking_modifications?.measures?.volume,
      projected_totalWt: booking_modifications?.measures?.weight,
      projected_pickupReasonCode:
        booking_modifications?.pickup_override?.change_reason,
    }

    const hasProjectedValues =
      booking_modifications?.action === SERVICE_SHIPMENT_ENUM.MODIFY
    const shipmentStatus = getShipmentStatus(
      curr,
      loadDetails,
      hasProjectedValues,
      isProjectedCancel,
      isInternal,
    )

    const hasFailedModification = status === SERVICE_SHIPMENT_ENUM.FAILED
    const isOnLoad = !!loadDetails?.loadId

    const isShipmentEditable = checkIsShipmentEditable(
      shipmentStatus,
      toBeCancelled,
      isCancelled,
      isOnLoad,
      isProjectedCancel,
      hasRequiredEntitlements,
    )

    const pickupDateToUse = getPickupDateToUse(curr, loadDetails)
    const transformedData = {
      appointmentNumber: curr.vend_appt_num,
      tloRefId: curr.tlo_ref_id,
      bookingId: curr.booking_id,
      bookingVersion: curr.booking_version,
      bol: curr.bol,
      carton: parseInt(curr.carton),
      chargeCode: curr.charge_code,
      createdBy: getCreatedBy(curr),
      commodityCode: curr.commodity_code,
      currentShipmentStatus: shipmentStatus,
      destLocCode: curr.dest_loc_code,
      destLocType: curr.dest_loc_type,
      department: curr.dept,
      hasFailedModification: hasFailedModification,
      hasInvalidDimensions: false,
      hasProjectedValues: hasProjectedValues,
      id: curr.tgt_booking_id,
      irdDate: curr.inventory_ready_date,
      isBookingVersionInvalid: false,
      isCancelled: isCancelled,
      isCancellable: isShipmentEditable,
      isEditable_appointmentNumber: isShipmentEditable,
      isEditable_bol: isShipmentEditable,
      isEditable_carton: isShipmentEditable,
      isEditable_commodityCode: isShipmentEditable && !isOnLoad,
      isEditable_pallet: isShipmentEditable,
      isEditable_pickupDate: isShipmentEditable,
      isEditable_pickupReasonCode: isShipmentEditable,
      isEditable_totalVol: isShipmentEditable,
      isEditable_totalWt: isShipmentEditable,
      isProcessing: curr.is_processing,
      isSmallPackage: curr.small_package,
      logisticsGroup: curr.logistics_group,
      origLocCode: curr.orig_loc_code,
      origLocType: curr.orig_loc_type,
      original_appointmentNumber: curr.vend_appt_num,
      original_bol: curr.bol,
      original_carton: parseInt(curr.carton),
      original_commodityCode: curr.commodity_code,
      original_pallet: curr.pallet,
      original_pickupDate: pickupDateToUse.pickupDate,
      original_pickupReasonCode: pickupDateToUse.pickupReasonCode,
      original_totalVol: parseInt(curr.total_vol),
      original_totalWt: parseInt(curr.total_wt),
      pallet: curr.pallet,
      pickupDate: pickupDateToUse.pickupDate,
      pickupFrom: curr.pickup_from,
      pickupReasonCode: pickupDateToUse.pickupReasonCode,
      pickupTo: curr.pickup_to,
      planId: curr.plan_id,
      ...projectedValues,
      purchaseOrderNumber: curr.po,
      loadDetails,
      loadId: loadDetails?.loadId,
      tgtBookingId: curr.tgt_booking_id,
      toBeCancelled: false,
      totalVol: parseInt(curr.total_vol),
      totalWt: parseInt(curr.total_wt),
      vendorReleased: curr.vendor_released,
      vendorId: curr.vendor_id,
      vendorRelease: curr.vendor_release,
      vmmLocationId,
      volUom: curr.vol_uom,
      vop: curr.vop,
      wtUom: curr.wt_uom,
      finalRoutingExpectedBy: curr.final_routing_expected_by,
      lastUpdatedBy: curr.last_updated_by,
      businessHrsBasedPickup: businessHrsBasedPickup,
      businessHours,
      closureDates,
      vendorName: curr.vendorName,
      originalShipDates: curr.originalShipDates,
      firstPickupDate: curr.original_pickup_date,
      shipmentType: curr.booking_properties?.shipment_type,
      subShipmentType: curr.booking_properties?.sub_shipment_type,
      moveType: curr.booking_properties?.move_type,
    }

    return {
      ...prev,
      [curr.tgt_booking_id]: transformedData,
    }
  }, {})
}
